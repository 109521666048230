import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import type { FC } from 'react';
import React, { useState } from 'react';
import { delay } from 'lodash';
import type { DetailOtpRegisterFormInterface } from './register-container';
import { Button } from '~components/button';
import { ButtonColors } from '~constants/components';
import TextSpreaderWidget from '~components/widgets/text-spreader-widget';
import { Countdown } from '~components/widgets/countdown-widget';

const ApproveOtpRegisterContainer: FC<{
  otpDetail: DetailOtpRegisterFormInterface;
  onSubmitForm: (otp: string) => void;
  onExpireOtp: () => void;
}> = (props) => {
  const { otpDetail, onSubmitForm, onExpireOtp } = props;
  const { t } = useTranslation(['forms', 'forgot-password']);
  const [otp, setOtp] = useState<{
    value: string;
    isLoading: boolean;
  }>({
    value: '',
    isLoading: false,
  });

  return (
    <div className="relative flex w-full flex-col gap-3">
      <div className="flex justify-center gap-2">
        <TextSpreaderWidget
          length={6}
          onChange={(value) => {
            setOtp({ ...otp, value });
          }}
        />
      </div>
      <p className="text-color text-center text-sm">
        ({t('forgot-password:otp-time-text')}{' '}
        <Countdown
          className="text-color-success font-semibold"
          onFinished={onExpireOtp}
          targetDate={dayjs(otpDetail.expiredAt).toDate()}
        />{' '}
        {t('forgot-password:minute-text')})
      </p>
      <Button
        color={ButtonColors.Primary}
        disabled={otp.isLoading || otp.value.length < 6}
        loading={otp.isLoading}
        onClick={() => {
          setOtp({ ...otp, isLoading: true });
          onSubmitForm(otp.value);
          delay(() => {
            setOtp({ value: '', isLoading: false });
          }, 2000);
        }}
      >
        {t('buttons:btn-confirm-otp')}
      </Button>
    </div>
  );
};

export default ApproveOtpRegisterContainer;
