import type { ComponentProps, ReactNode } from 'react';
import { forwardRef, useState } from 'react';
import type { SizeTypes } from '~constants/components';
import { useTheme } from '~contexts/theme';
import getInputStyle from '~libs/components/get-input-style';

export const Input = forwardRef<
  HTMLInputElement,
  Omit<ComponentProps<'input'>, 'size' | 'prefix'> & {
    prefix?: ReactNode;
    suffix?: ReactNode;
    value?: string | number;
    size?: SizeTypes;
    placeholder?: string;
    errors?: string[];
    containerProps?: Record<string, never>;
    label?: string;
  }
>(
  (
    {
      prefix,
      suffix,
      size,
      errors,
      className,
      label,
      containerProps,
      readOnly,
      type,
      ...rest
    },
    ref,
  ) => {
    const { input } = useTheme();
    const { defaultProps } = input;

    const {
      containerClasses,
      textErrorClasses,
      prefixClasses,
      suffixClasses,
      labelClasses,
      inputClasses,
      inputError,
    } = getInputStyle({
      ...input,
      containerProps,
      errors,
      className: className || defaultProps?.className,
      size: size || defaultProps?.size,
      label: label || defaultProps?.label,
      prefix: prefix || defaultProps?.prefix,
      readOnly,
    });

    const isPassword = type === 'password';
    const [isShowPassword, setIsShowPassword] = useState<boolean>(isPassword);

    const handleClickShowPassword = (): void => {
      setIsShowPassword(!isShowPassword);
    };

    return (
      <div {...containerProps} className={containerClasses}>
        {label ? <span className={labelClasses}>{label}</span> : null}
        {prefix ? <div className={prefixClasses}>{prefix}</div> : null}
        {suffix ? <div className={suffixClasses}>{suffix}</div> : null}
        {isPassword ? (
          <div
            className={suffixClasses}
            onClick={handleClickShowPassword}
            role="presentation"
          />
        ) : null}
        <input
          readOnly={readOnly}
          ref={ref}
          type={isShowPassword ? 'password' : 'text'}
          {...rest}
          className={inputClasses}
        />
        {inputError ? (
          <div className="flex flex-col gap-1">
            {errors?.map((message, i) => (
              <div className={textErrorClasses} key={i}>
                {message}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    );
  },
);

Input.displayName = 'Input';
