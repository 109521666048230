import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import type { FC } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { delay, get } from 'lodash';
import { PhoneOutlined } from '@packages/icons-react';
import { Button } from '~components/button';
import checkPhoneNumberFromRegion from '~libs/check-phone-number-from-region';
import { DEFAULT_REGION_CODE } from '~constants/etc';
import InputPhoneNumberWidget from '~components/widgets/input-phone-number-widget';

const RequestOtpRegisterContainer: FC<{
  region: string;
  onSubmitForm: (phoneNumber: string) => void;
}> = (props) => {
  const { region, onSubmitForm } = props;
  const { t } = useTranslation(['forms']);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [regionCode, setRegionCode] = useState<string>(
    region || DEFAULT_REGION_CODE,
  );

  const schemaErrorsRegisterForm = Joi.object({
    phoneNumber: Joi.string()
      .required()
      .messages({
        'string.empty': t('forms:error-phone-number-empty'),
        'any.required': t('forms:error-phone-number-empty'),
      }),
  }).unknown(true);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<{
    phoneNumber: string;
  }>({
    resolver: joiResolver(schemaErrorsRegisterForm),
  });

  const handleCodeChange = (value: string): void => {
    setRegionCode(value);
  };

  const handleSubmitForm = (values: { phoneNumber: string }): void => {
    const parsedPhoneNumber = checkPhoneNumberFromRegion(
      values.phoneNumber,
      regionCode,
    );
    const phoneNumber = get(parsedPhoneNumber, 'number.e164', '');

    if (!parsedPhoneNumber.valid) {
      setError('phoneNumber', {
        type: 'custom',
        message: t('forms:error-phone-number-format'),
      });
      return;
    }
    onSubmitForm(phoneNumber);
  };

  return (
    <form
      className="relative flex w-full flex-col gap-3"
      onSubmit={handleSubmit((values) => {
        setIsSubmitting(true);
        handleSubmitForm(values);
        delay(() => {
          setIsSubmitting(false);
        }, 2000);
      })}
    >
      <InputPhoneNumberWidget
        errors={errors}
        onCodeChange={handleCodeChange}
        prefix={<PhoneOutlined className="mt-0.5 text-sm" />}
        region={regionCode}
        register={register('phoneNumber')}
      />
      <Button disabled={isSubmitting} loading={isSubmitting} type="submit">
        ขอรหัส OTP
      </Button>
    </form>
  );
};

export default RequestOtpRegisterContainer;
