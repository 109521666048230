'use client';

import { Listbox } from '@headlessui/react';
import { CaretDownOutlined } from '@packages/icons-react';
import { cn } from 'class-merge';
import type { FC, ReactNode } from 'react';
import { useMemo, useState } from 'react';

export interface SelectOptionProps {
  key?: string;
  value: any;
  label: ReactNode;
  disabled?: boolean;
}

export interface SelectProps {
  visibleArrow?: boolean;
  disabled?: boolean;
  defaultSelectedOption: any;
  options: SelectOptionProps[];
  name?: string;
  className?: string;
  wrapperClassName?: string;
  optionClassName?: string;
  optionsClassName?: string;
  onSelect?: (selectedOption: any) => void;
}

export const Select: FC<SelectProps> = (props: SelectProps) => {
  const {
    visibleArrow = true,
    disabled,
    name,
    className,
    wrapperClassName,
    optionsClassName,
    defaultSelectedOption,
    options,
    onSelect,
  } = props;

  const defaultIndex = useMemo(() => {
    const index = options.findIndex((o) => o.value === defaultSelectedOption);
    if (index > 0) {
      return index;
    }
    return 0;
  }, [options, defaultSelectedOption]);

  const [selected, setSelected] = useState(options[defaultIndex]);

  return (
    <Listbox
      disabled={disabled}
      name={name}
      onChange={(value) => {
        setSelected(value);
        if (typeof onSelect === 'function') {
          onSelect(value);
        }
      }}
      value={selected}
    >
      <div className={cn('select-wrapper relative', wrapperClassName)}>
        <Listbox.Button
          className={cn(
            'h-10 w-full px-4',
            'bg-evaluated  flex items-center justify-between',
            'border-evaluated  rounded-3xl border',
            disabled && 'cursor-not-allowed opacity-80',
            className,
          )}
        >
          <span>{selected.label}</span>

          {visibleArrow ? (
            <CaretDownOutlined aria-hidden="true" className="text-2xl" />
          ) : null}
        </Listbox.Button>
        <Listbox.Options
          className={cn(
            'absolute top-10 z-50 mt-4 max-h-40 w-full focus:outline-none',
            'overflow-auto text-base sm:text-sm',
            'bg-evaluated rounded-2xl shadow-lg',
            optionsClassName,
          )}
        >
          {options.map((o, i) => {
            return (
              <Listbox.Option
                className={({ active }) =>
                  cn(
                    'text-color relative z-50 cursor-pointer select-none px-4 py-3',
                    {
                      'bg-secondary': active,
                      'cursor-not-allowed opacity-30': o.disabled,
                    },
                    // optionClassName,
                  )
                }
                disabled={o.disabled}
                key={o.key || i}
                value={o}
              >
                {o.label}
              </Listbox.Option>
            );
          })}
        </Listbox.Options>
      </div>
    </Listbox>
  );
};
