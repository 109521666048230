import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { OtpKeyEnum } from '~constants/etc';

export interface OtpDataProps {
  phoneNumber: string;
  type: string;
  ref: string;
  status: string;
  createdAt: Date | string;
  expiredAt: Date | string;
  updatedAt: Date | string;
  meta?: Record<string, string | number | boolean>;
}

const otpStore = persist<any>(
  (set, get) => ({
    otpLists: {
      [OtpKeyEnum.OtpUpdateProfile]: null,
      [OtpKeyEnum.OtpResetPassword]: null,
      [OtpKeyEnum.OtpRegisterForm]: null,
    },
    setOtpData: (key: string) => {
      return (newValue: any) => {
        const otpData = get().otpLists;
        set(() => ({
          otpLists: {
            ...otpData,
            [key]: newValue,
          },
        }));
      };
    },
  }),
  {
    name: 'otp-lists',
    partialize: (state) => ({
      otpLists: state.otpLists,
    }),
    version: 1,
  },
);

export const useOtpStore = create(otpStore);
