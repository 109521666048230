import type { ParsedPhoneNumber } from 'awesome-phonenumber';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { DEFAULT_REGION_CODE } from '~constants/etc';

const checkPhoneNumberFromRegion = (
  phoneNumber: string,
  regionCode: string,
): ParsedPhoneNumber => {
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber, {
    regionCode,
  });
  if (parsedPhoneNumber.possibility === 'invalid-country-code') {
    return parsePhoneNumber(phoneNumber, {
      regionCode: DEFAULT_REGION_CODE,
    });
  }
  return parsedPhoneNumber;
};

export default checkPhoneNumberFromRegion;
