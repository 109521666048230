import type { ReactNode } from 'react';
import { cn } from 'class-merge';
import type { InputStylesType } from 'themes/components/input';
import { objectsToString } from '~libs/objects-to-string';
import { SizeTypes } from '~constants/components';

const getInputStyle = (
  input: InputStylesType & {
    prefix?: ReactNode;
    value?: string | number;
    placeholder?: string;
    size?: SizeTypes;
    errors?: string[];
    className?: string;
    containerProps?: Record<string, never>;
    label?: string;
    readOnly?: boolean;
  },
): Record<string, any> => {
  const {
    styles,
    containerProps,
    className,
    errors,
    size,
    label,
    prefix,
    readOnly,
  } = input;
  const { base, variants } = styles;

  const inputSize = variants.sizes[size || SizeTypes.Large];
  const inputError = Boolean(errors?.length);

  const containerClasses = cn(
    objectsToString(base.container),
    label && objectsToString(variants.label),
    containerProps?.className,
  );

  const inputClasses = cn(
    objectsToString(base.input),
    objectsToString(inputSize.input),
    prefix && objectsToString(variants.base.inputWithPrefix),
    inputError && objectsToString(variants.errors.input),
    readOnly && objectsToString(variants.readOnly.input),
    className,
  );

  const prefixClasses = cn(
    objectsToString(base.prefix),
    objectsToString(inputSize.prefix),
    label && objectsToString(inputSize.prefixWithLabel),
  );

  const suffixClasses = objectsToString(base.suffix);
  const labelClasses = objectsToString(base.label);
  const textErrorClasses = objectsToString(base.errorText);

  return {
    containerClasses,
    textErrorClasses,
    prefixClasses,
    suffixClasses,
    labelClasses,
    inputClasses,
    inputSize,
    inputError,
  };
};

export default getInputStyle;
