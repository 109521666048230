import { cn } from 'class-merge';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import { useState, type FC } from 'react';
import { S3_BUCKET_IMAGES } from '~constants/etc';

interface ProviderCardInterface {
  logo: string;
  title: string;
  image: string;
  type: string;
  className?: string;
  onOpenGame: () => void;
}

const ProviderCard: FC<ProviderCardInterface> = ({
  image,
  logo,
  title = '???',
  type,
  className,
  onOpenGame,
}) => {
  const { t } = useTranslation('common');
  const [isError, setIsError] = useState<boolean>(false);

  return (
    <button
      className={cn(
        'relative h-[120px] w-full max-w-[124px] overflow-hidden rounded-2xl',
        'transform transition-all duration-300 hover:scale-105',
        'from-gradient-top-provider-card to-gradient-bottom-provider-card bg-gradient-to-b',
        className,
      )}
      onClick={onOpenGame}
    >
      {!isError ? (
        <>
          <Image
            alt={title}
            className="absolute left-0 top-0 max-h-[95px] max-w-[100px]"
            draggable={false}
            fill
            onError={() => {
              setIsError(true);
            }}
            priority
            src={image}
            unoptimized
          />
          <Image
            alt={logo}
            className="absolute right-1.5 top-8"
            draggable={false}
            height={32}
            onError={() => {
              setIsError(true);
            }}
            src={logo}
            unoptimized
            width={32}
          />
        </>
      ) : (
        <div className="flex items-center justify-center">
          <Image
            alt="logo"
            className="mb-2 ml-1.5"
            draggable={false}
            height={80}
            src={`${S3_BUCKET_IMAGES}/logo.webp`}
            unoptimized
            width={80}
          />
        </div>
      )}
      <div className="absolute right-0 top-0 bg-gradient-to-l from-black to-transparent px-3.5 py-0.5">
        <span className="text-xs">
          {type
            ? t(`menu.${type}`, { defaultValue: type })
            : t('not-found-category')}
        </span>
      </div>
      <div className="bg-light absolute bottom-0 left-0 flex h-6 w-full items-center justify-center text-center">
        <div className="w-[90%] truncate text-xs capitalize">
          {title || t('not-found-provider')}
        </div>
      </div>
    </button>
  );
};
export default ProviderCard;
