import { useTranslation } from 'react-i18next';
import type { FC } from 'react';
import { Button } from '~components/button';
import { ButtonColors, SizeTypes } from '~constants/components';
import SignInContainer from '~containers/sign-in-container';
import RegisterContainer from '~containers/register/register-container';
import { useDialog } from '~hooks/use-dialog';
import { ClientSideRender } from '~components/layouts/client-side-render';
import type { AgentInfoResponseInterface } from '~libs/get-agent-info';

const UnauthorizedNavigation: FC<{
  agentInfo: AgentInfoResponseInterface;
  region: string;
  className?: string;
}> = ({ agentInfo, region }) => {
  const { t } = useTranslation('common');
  const [dialog, contextHolder] = useDialog();

  const handleLogin = (): void => {
    dialog.content({
      children: (
        <ClientSideRender>
          <SignInContainer
            onClose={() => {
              dialog.destroy();
            }}
          />
        </ClientSideRender>
      ),
    });
  };

  const handleRegister = (): void => {
    dialog.content({
      children: (
        <ClientSideRender>
          <RegisterContainer
            agentInfo={agentInfo}
            onClose={() => {
              dialog.destroy();
            }}
            region={region}
          />
        </ClientSideRender>
      ),
    });
  };

  return (
    <>
      {contextHolder}
      <Button
        className="w-full border md:w-[180px]"
        color={ButtonColors.Transparent}
        onClick={() => {
          handleLogin();
        }}
        size={SizeTypes.Small}
      >
        {t('login')}
      </Button>
      <Button
        className="w-full md:w-[180px]"
        color={ButtonColors.Primary}
        onClick={() => {
          handleRegister();
        }}
        size={SizeTypes.Small}
      >
        {t('register')}
      </Button>
    </>
  );
};

export default UnauthorizedNavigation;
