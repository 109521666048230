import { joiResolver } from '@hookform/resolvers/joi';
import { LockStarOutlined, UserOutlined } from '@packages/icons-react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import Joi from 'joi';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import type { FC } from 'react';
import type { DetailOtpRegisterFormInterface } from './register-container';
import { Button } from '~components/button';
import { Input } from '~components/input';
import request from '~libs/request';
import HttpErrorHandler from '~components/http-error-handler';
import { Countdown } from '~components/widgets/countdown-widget';

export interface UpdateRegisterFormProps {
  username: string;
  password: string;
  confirmPassword: string;
}

interface RegisterFormProps {
  otpRegisterForm?: DetailOtpRegisterFormInterface;
  onExpireOtp: () => void;
  onSubmitForm: SubmitHandler<UpdateRegisterFormProps>;
}

const RegisterFormContainer: FC<RegisterFormProps> = (props) => {
  const { otpRegisterForm, onExpireOtp, onSubmitForm } = props;
  const { t } = useTranslation(['forms', 'buttons', 'forgot-password']);

  const schemaErrorsRegisterForm = Joi.object({
    username: Joi.string()
      .required()
      .min(4)
      .max(16)
      .pattern(/^[A-Za-z0-9]+$/)
      .messages({
        'any.required': t('forms:error-username-empty'),
        'string.empty': t('forms:error-username-empty'),
        'string.max': t('forms:error-specify-more-than-characters', {
          value: 16,
        }),
        'string.min': t('forms:error-specify-less-than-characters', {
          value: 4,
        }),
        'string.pattern.base': t('forms:error-username-format'),
      }),
    password: Joi.string()
      .required()
      .min(6)
      .max(20)
      // .pattern(/(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])/)
      .messages({
        'any.required': t('forms:error-password-empty'),
        'string.empty': t('forms:error-password-empty'),
        'string.max': t('forms:error-specify-more-than-characters', {
          value: 20,
        }),
        'string.min': t('forms:error-specify-less-than-characters', {
          value: 6,
        }),
        // 'string.pattern.base': 'รหัสผ่านมีรูปแบบที่ไม่ถูกต้อง',
      }),
    confirmPassword: Joi.string()
      .valid(Joi.ref('password'))
      .required()
      .messages({
        'any.only': t('forms:error-confirm-password-fail'),
      }),
  }).unknown(true);

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<UpdateRegisterFormProps>({
    resolver: joiResolver(schemaErrorsRegisterForm),
  });

  const handleCheckUsernameExist = debounce((username) => {
    request({
      url: '/users/exists',
      method: 'GET',
      params: {
        username,
      },
    })
      .then(({ isExist }) => {
        if (isExist === true) {
          setError('username', {
            type: 'username_exist',
            message: t('forms:error-username-exist'),
          });
          return;
        }
        clearErrors('username');
      })
      .catch((err) => {
        HttpErrorHandler(err);
      });
  }, 2000);

  return (
    <form
      className="relative flex w-full flex-col gap-3"
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <Input
        {...register('username')}
        errors={
          errors.username?.message ? [errors.username.message] : undefined
        }
        onChange={(e) => {
          if (e.target.value.length >= 4) {
            handleCheckUsernameExist(e.target.value.toLowerCase());
          }
        }}
        placeholder={t('forms:place-holder-username')}
        prefix={<UserOutlined />}
      />
      <Input
        {...register('password')}
        errors={
          errors.password?.message ? [errors.password.message] : undefined
        }
        placeholder={t('forms:place-holder-password')}
        prefix={<LockStarOutlined />}
        type="password"
      />

      <Input
        {...register('confirmPassword')}
        errors={
          errors.confirmPassword?.message
            ? [errors.confirmPassword.message]
            : undefined
        }
        placeholder={t('forms:place-holder-confirm-password')}
        prefix={<LockStarOutlined />}
        type="password"
      />
      {otpRegisterForm ? (
        <p className="text-color text-center text-sm">
          ({t('forgot-password:otp-time-text')}{' '}
          <Countdown
            className="text-color-success font-semibold"
            onFinished={onExpireOtp}
            targetDate={dayjs(otpRegisterForm.expiredAt).toDate()}
          />{' '}
          {t('forgot-password:minute-text')})
        </p>
      ) : null}
      <Button
        disabled={isSubmitting || Boolean(Object.keys(errors).length)}
        loading={isSubmitting}
        type="submit"
      >
        {t('buttons:btn-open-account')}
      </Button>
    </form>
  );
};

export default RegisterFormContainer;
