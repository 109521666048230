import { AnnounceFilled } from '@packages/icons-react';
import { cn } from 'class-merge';
import React from 'react';

interface MarqueeProps {
  text: string;
  className?: string;
}

const Marquee: React.FC<MarqueeProps> = ({ text, className }) => {
  return (
    <div
      className={cn(
        className,
        'bg-default relative w-full overflow-hidden whitespace-nowrap py-2',
      )}
    >
      <div className="animate-marquee flex items-center space-x-10 text-xs font-bold text-white">
        <span className="flex gap-x-2">
          <AnnounceFilled className="text-base" />
          {text}
        </span>
        <span className="flex gap-x-2">
          <AnnounceFilled className="text-base" />
          {text}
        </span>
      </div>
    </div>
  );
};

export default Marquee;
