import { useMemo, type FC } from 'react';
import { get } from 'radash';
import { CloseOutlined } from '@packages/icons-react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useSearchParams } from 'next/navigation';
import jwt from 'jsonwebtoken';
import dayjs from 'dayjs';
import { signIn } from 'next-auth/react';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import { useTranslation } from 'react-i18next';
import SuggestedInDialogWidget from '~components/widgets/suggested-in-dialog-widget';
import type { AgentInfoResponseInterface } from '~libs/get-agent-info';
import RegisterNotEnabledContainer from '~containers/register/register-not-enabled-container';
import { useOtpStore } from '~stores/otp-lists-store';
import { OtpKeyEnum } from '~constants/etc';
import ApproveOtpRegisterContainer from '~containers/register/approve-otp-register-container';
import RequestOtpRegisterContainer from '~containers/register/request-otp-register-container';
import HttpErrorHandler from '~components/http-error-handler';
import { axiosInstance } from '~libs/axios-instance';
import type { UpdateRegisterFormProps } from '~containers/register/register-form-container';
import RegisterFormContainer from '~containers/register/register-form-container';
import { encrypt } from '~libs/encrypt';
import { useDialog } from '~hooks/use-dialog';
import PageUrls from '~constants/page-urls';
import ActivitySuccessContainer from '~containers/activity-success-container';
import { useRouter } from '~hooks/use-router';

interface OtpRegisterInterface {
  agent: string;
  countryCode: string;
  createdAt: Date | string;
  expiredAt: Date | string;
  meta: any;
  phoneNumber: string;
  ref: string;
  regionCode: string;
  status: string;
  type: string;
  updatedAt: Date | string;
  _id: string;
}

export interface DetailOtpRegisterFormInterface {
  ref: string;
  agent: string;
  countryCode: string;
  phoneNumber: string;
  regionCode: string;
  type: string;
  expiredAt: Date | string;
}

const RegisterContainer: FC<{
  agentInfo: AgentInfoResponseInterface;
  region: string;
  onClose: () => void;
}> = ({ agentInfo, region, onClose }) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const cookies = parseCookies();
  const referralId = searchParams.get('ref') || cookies.referralId || undefined;
  const subAgentReferralCode =
    searchParams.get('sa') || cookies.subAgentReferralCode || undefined;
  const [dialog, contextHolder] = useDialog();
  const { t } = useTranslation('common');
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { otpLists: otpRegisterForm, setOtpData: setOtpRegisterForm } =
    useOtpStore((state) => {
      return {
        otpLists: state.otpLists[OtpKeyEnum.OtpRegisterForm],
        setOtpData: state.setOtpData(OtpKeyEnum.OtpRegisterForm),
      };
    });
  const isEnableRegister = get(agentInfo, 'config.app.enableRegister', true);
  const isRequiredPhoneNumber = get(
    agentInfo,
    'config.app.phoneRequiredForRegistration',
    false,
  );

  const requestOtpRegisterForm = async (phoneNumber: string): Promise<void> => {
    try {
      if (!executeRecaptcha) {
        return;
      }
      const token = await executeRecaptcha('login');
      const { data } = await axiosInstance.post<OtpRegisterInterface>(
        '/otp/register',
        {
          phoneNumber,
          token,
          agent: {
            agentName: agentInfo.agentName,
          },
        },
      );
      setOtpRegisterForm({
        ref: data.ref,
        agent: data.agent,
        countryCode: data.countryCode,
        phoneNumber: data.phoneNumber,
        regionCode: data.regionCode,
        type: data.type,
        expiredAt: data.expiredAt,
      });
    } catch (error) {
      HttpErrorHandler(error);
    }
  };

  const requestLogin = async (
    username: string,
    password: string,
  ): Promise<void> => {
    let response;
    try {
      if (!executeRecaptcha) {
        return;
      }
      const token = await executeRecaptcha('login');
      response = await axiosInstance.post('/authentication/login', {
        username,
        password,
        token,
      });
    } catch (error) {
      HttpErrorHandler(error);
    }
    if (response) {
      const jwtDecoded = jwt.decode(response.data.accessToken);
      setCookie(undefined, 'access_token', response.data.accessToken, {
        maxAge: dayjs(jwtDecoded.exp * 1000).diff(dayjs(), 's'),
        path: '/',
      });
      await signIn('credentials', {
        accessToken: response.data.accessToken,
      });
    }
  };

  const handleSubmitForm = async (
    values: UpdateRegisterFormProps,
  ): Promise<void> => {
    let response;
    const hashPassword = encrypt(values.password);
    try {
      if (!executeRecaptcha) {
        return;
      }
      const token = await executeRecaptcha('register');
      response = await axiosInstance.post('/users/register', {
        username: values.username.trim().toLowerCase(),
        password: hashPassword,
        subAgentReferralCode: referralId ? undefined : subAgentReferralCode,
        phoneNumber: otpRegisterForm?.phoneNumber || undefined,
        otp: otpRegisterForm?.otp || undefined,
        otpRef: otpRegisterForm?.ref || undefined,
        referralId,
        token,
      });
    } catch (error) {
      const errorMessage = get(error, 'response.data.message', '');
      if (String(errorMessage) === 'invalid otp') {
        setOtpRegisterForm({ ...otpRegisterForm, otp: undefined });
      }
      HttpErrorHandler(error);
    }

    if (response) {
      setOtpRegisterForm(null);
      destroyCookie(undefined, 'referralId');
      destroyCookie(undefined, 'subAgentReferralCode');
      dialog.content({
        children: (
          <ActivitySuccessContainer
            description={
              <div className="flex flex-col text-center leading-tight">
                <p className="font-semibold">{t('register-success-title')}</p>
                <p>{t('register-success-description')}</p>
              </div>
            }
            onClickPrimaryButton={() => {
              requestLogin(values.username.trim().toLowerCase(), hashPassword);
            }}
            onClickSecondaryButton={() => {
              router.push(PageUrls.Contact);
            }}
            textBtnPrimary="btn-login"
            textBtnSecondary="btn-contact"
            title={t('welcome-text')}
          />
        ),
      });
    }
  };

  const registerContents = useMemo(() => {
    if (!isEnableRegister) {
      return <RegisterNotEnabledContainer />;
    }

    if (otpRegisterForm && !otpRegisterForm.otp) {
      return (
        <ApproveOtpRegisterContainer
          onExpireOtp={() => setOtpRegisterForm(null)}
          onSubmitForm={(otp) => {
            setOtpRegisterForm({ ...otpRegisterForm, otp });
          }}
          otpDetail={otpRegisterForm}
        />
      );
    }
    if (isRequiredPhoneNumber && !otpRegisterForm) {
      return (
        <RequestOtpRegisterContainer
          onSubmitForm={requestOtpRegisterForm}
          region={region}
        />
      );
    }
    return (
      <RegisterFormContainer
        onExpireOtp={() => setOtpRegisterForm(null)}
        onSubmitForm={handleSubmitForm}
        otpRegisterForm={otpRegisterForm}
      />
    );
  }, [region, otpRegisterForm, isEnableRegister, isRequiredPhoneNumber]);

  return (
    <div className="bg-secondary relative h-screen w-screen p-6 sm:h-full sm:max-w-[600px] sm:rounded-3xl sm:border-4 sm:border-white">
      {contextHolder}
      <div
        aria-hidden
        className="absolute right-4 top-4 flex h-[25px] w-[25px] cursor-pointer items-center justify-center rounded-full bg-white text-sm text-black"
        onClick={onClose}
        role="button"
      >
        <CloseOutlined />
      </div>
      <SuggestedInDialogWidget />
      {registerContents}
    </div>
  );
};

export default RegisterContainer;
