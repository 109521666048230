'use client';

import { useEffect, useMemo } from 'react';
import { ClientSideRender } from '~components/layouts/client-side-render';
import { useCountdown } from '~hooks/use-countdown';

interface CountdownProps {
  targetDate: Date;
  className?: string;
  onFinished?: () => void;
}

export const Countdown: React.FC<CountdownProps> = (props) => {
  const { targetDate, className, onFinished } = props;

  const [d, h, m, s] = useCountdown(targetDate);

  const finished = useMemo(() => d + h + m + s <= 0, [d, h, m, s]);

  useEffect(() => {
    if (finished && typeof onFinished === 'function') {
      onFinished();
    }
  }, [onFinished, finished]);

  return (
    <ClientSideRender>
      <span className={className}>
        {!finished ? (h + d * 24).toString().padStart(2, '0') : '00'}:
        {!finished ? m.toString().padStart(2, '0') : '00'}:
        {!finished ? s.toString().padStart(2, '0') : '00'}
      </span>
    </ClientSideRender>
  );
};
