import { useTranslation } from 'react-i18next';
import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';
import type { FieldErrors, UseFormRegisterReturn } from 'react-hook-form';
import Image from 'next/image';
import { getCountryCodeForRegionCode } from 'awesome-phonenumber';
import { Input } from '~components/input';
// import { Select } from '~components/select';
import { RegionEnum } from '~constants/etc';
import { Select } from '~components/select';

interface InputPhoneNumberWidgetProps {
  region: string;
  disabled?: boolean;
  readOnly?: boolean;
  register: UseFormRegisterReturn<'phoneNumber'>;
  prefix?: ReactNode;
  errors: FieldErrors<{ phoneNumber: string }>;
  onCodeChange: (value: string) => void;
}

const InputPhoneNumberWidget: FC<InputPhoneNumberWidgetProps> = (props) => {
  const {
    region,
    disabled = false,
    readOnly = false,
    register,
    prefix,
    errors,
    onCodeChange,
  } = props;
  const { t } = useTranslation('forms');
  const regionCodeOptions = useMemo(() => {
    return [RegionEnum.TH, RegionEnum.VN].map((code) => {
      const regionCode = getCountryCodeForRegionCode(code);
      return {
        label: (
          <div className="flex items-center gap-1" key={code}>
            <Image
              alt={`${code}`}
              className="rounded"
              draggable={false}
              height={5}
              src={`https://flagcdn.com/h60/${code}.png`}
              width={30}
            />
            {`+${regionCode}`}
          </div>
        ),
        value: code,
      };
    });
  }, []);

  return (
    <div className="flex flex-col gap-3 sm:flex-row">
      {!readOnly && (
        <Select
          className="h-[50px] w-full text-white outline-none sm:w-[130px]"
          defaultSelectedOption={region}
          disabled={disabled}
          onSelect={(e) => {
            onCodeChange(e.value);
          }}
          options={regionCodeOptions}
        />
      )}
      <Input
        {...register}
        disabled={disabled}
        errors={
          errors.phoneNumber?.message ? [errors.phoneNumber.message] : undefined
        }
        placeholder={t('place-holder-phone-number')}
        prefix={prefix}
        readOnly={readOnly}
      />
    </div>
  );
};

export default InputPhoneNumberWidget;
