import Image from 'next/image';
import type { FC } from 'react';
import { S3_BUCKET_IMAGES } from '~constants/etc';

const SuggestedInDialogWidget: FC = () => {
  return (
    <div className="flex flex-col items-center">
      <Image
        alt="logo"
        className="h-[120px] w-[180px]"
        draggable={false}
        height={150}
        src={`${S3_BUCKET_IMAGES}/logo.webp`}
        width={150}
      />
      <p className="text-primary text-lg font-bold sm:text-2xl">
        Where Emotions No Limits
      </p>
      <div className="my-8 flex w-full flex-col gap-4">
        <div className="flex justify-between">
          <div className="flex flex-col leading-tight">
            <p className="text-xs font-semibold sm:text-base">
              Ronaldinho Brand
            </p>
            <p className="text-sm">Ambassador (2024 - 2025)</p>
          </div>
          <Image
            alt="signature-ronaldinho"
            draggable={false}
            height={20}
            src={`${S3_BUCKET_IMAGES}/signature-ronaldinho.webp`}
            width={90}
          />
        </div>
        <div className="flex justify-between">
          <div className="flex flex-col leading-tight">
            <p className="text-xs font-semibold sm:text-base">
              Official Partner 2023 - 2025
            </p>
            <p className="text-sm">Villarreal CF & OKVIP</p>
          </div>
          <Image
            alt="villarreal"
            className="h-[35px] w-[140px]"
            draggable={false}
            height={150}
            src={`${S3_BUCKET_IMAGES}/villarreal.webp`}
            width={150}
          />
        </div>
      </div>
    </div>
  );
};

export default SuggestedInDialogWidget;
