import type { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '~components/button';
import { ButtonColors } from '~constants/components';

const RegisterNotEnabledContainer: FC = () => {
  const { t } = useTranslation(['buttons', 'register']);
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col items-center gap-0.5">
        <Trans
          components={[
            <p className="text-xl font-semibold" key={0} />,
            <p key={1} />,
          ]}
          i18nKey="register:title-register-not-enabled"
        />
      </div>
      <div className="text-color/80 flex flex-col gap-1 border-b border-white/10 pb-8 text-center">
        <Trans
          components={[<p key={0} />, <p key={1} />]}
          i18nKey="register:description-register-not-enabled"
        />
      </div>
      <Button className="mt-4" color={ButtonColors.Primary}>
        {t('buttons:btn-contact')}
      </Button>
    </div>
  );
};

export default RegisterNotEnabledContainer;
