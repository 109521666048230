'use client';

import { useState, useEffect } from 'react';
import { parseCookies } from 'nookies';
import { GetServerSidePropsContext } from 'next';

const useAuth = (ctx?: GetServerSidePropsContext) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(() => {
    const cookies = parseCookies(ctx);
    return !!cookies.access_token;
  });

  useEffect(() => {
    if (!ctx) {
      const cookies = parseCookies();
      setIsLoggedIn(!!cookies.access_token);
    }
  }, [ctx]);

  return { isLoggedIn };
};

export default useAuth;
