import times from 'lodash/times';
import type { FC } from 'react';
import { useRef } from 'react';
import { Input } from '~components/input';

const TextSpreaderWidget: FC<{
  length: number;
  onChange: (value: string) => void;
}> = (props) => {
  const { length, onChange } = props;
  const inputElems = useRef<any>([]);

  const handleInputChange = (): void => {
    const values = inputElems.current.map((input) => input.value);
    onChange(values.join(''));
  };

  const autoTab = (e: { keyCode: number }, index: number): void => {
    if (
      (e.keyCode >= 48 && e.keyCode <= 57) ||
      (e.keyCode >= 96 && e.keyCode <= 105)
    ) {
      if (inputElems.current[index + 1]) {
        inputElems.current[index + 1].focus();
      }
    }
    if ([8, 46].includes(e.keyCode)) {
      if (inputElems.current[index - 1]) {
        inputElems.current[index - 1].focus();
      }
    }
  };

  return times(length).map((index) => (
    <div className="w-[52px] sm:w-[55px]" key={index}>
      <Input
        autoFocus={index === 0}
        className="h-[40px] w-[52px] text-center sm:h-[50px] sm:w-[55px]"
        maxLength={1}
        onChange={() => {
          handleInputChange();
        }}
        onKeyUp={(e) => {
          autoTab(e, index);
        }}
        ref={(ref) => {
          inputElems.current[index] = ref;
        }}
      />
    </div>
  ));
};

export default TextSpreaderWidget;
