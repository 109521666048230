import { CheckOutlined } from '@packages/icons-react';
import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import { delay } from 'lodash';
import { S3_BUCKET_IMAGES } from '~constants/etc';
import { Button } from '~components/button';
import { ButtonVariants } from '~constants/components';

interface ActivitySuccessContainerProps {
  title: string;
  description: ReactNode;
  textBtnPrimary?: string;
  textBtnSecondary?: string;
  onClickPrimaryButton: () => void;
  onClickSecondaryButton?: () => void;
}

const ActivitySuccessContainer: FC<ActivitySuccessContainerProps> = (props) => {
  const {
    title,
    description,
    textBtnPrimary = 'btn-next',
    textBtnSecondary,
    onClickPrimaryButton,
    onClickSecondaryButton,
  } = props;
  const { t } = useTranslation('buttons');
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);

  const handleButtonClick = (callback: () => void) => {
    setIsLoadingButton(true);
    callback();
    delay(() => {
      setIsLoadingButton(false);
    }, 3000);
  };

  return (
    <div className="bg-secondary relative h-screen w-screen p-6 sm:h-full sm:max-w-[600px] sm:rounded-3xl sm:border-4 sm:border-white">
      <div className="flex flex-col items-center">
        <Image
          alt="logo"
          className="h-[120px] w-[180px]"
          draggable={false}
          height={150}
          src={`${S3_BUCKET_IMAGES}/logo.webp`}
          width={150}
        />
        <p className="text-primary text-lg font-bold sm:text-2xl">{title}</p>
        <CheckOutlined className="text-primary my-6 text-5xl" />
        {description}
        <div className="mt-6 flex w-full flex-col gap-2">
          <Button
            block
            disabled={isLoadingButton}
            loading={isLoadingButton}
            onClick={() => {
              handleButtonClick(onClickPrimaryButton);
            }}
          >
            {t(textBtnPrimary as any)}
          </Button>
          {textBtnSecondary && typeof onClickSecondaryButton === 'function' ? (
            <Button
              block
              disabled={isLoadingButton}
              loading={isLoadingButton}
              onClick={() => {
                handleButtonClick(onClickSecondaryButton);
              }}
              variant={ButtonVariants.Ghost}
            >
              {t(textBtnSecondary as any)}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ActivitySuccessContainer;
